.shop{
    padding-bottom: 16px;
}

.shop-tab-wrap{
    display: flex;
    justify-content: center;
    margin-bottom: 2px;
}

.shop-tab-item{
    padding: 1px 2px;
    border:1px solid rgb(214, 214, 214);
    background-color: rgb(214, 214, 214);
    color: gray;
}

.shop-content-wrap{
    margin: 0 auto;
}

.shop-content-wrap td,.shop-content-wrap th{
    border: 1px solid rgb(214, 214, 214);
    padding: 2px 4px;
}

.shop-tab-item[data-shop-current="true"]{
    /* border:1px solid rgb(214, 214, 214); */
    background-color: white;
    color: black;

}